$(document).ready(function() {
	var mq = window.matchMedia('(max-width: 1096px)');
	if(mq.matches) {
		//alert('ok');
		/*** Image Swaps ***/
		$('#header .logo.main img').attr('src', 'css/images/logo-mobile.jpg');

		/*** Back to top button ***/
		$('#footer button.backToTop').click(function() {
			$('html, body').stop().animate({ scrollTop : 0 }, 500);
		});

		/*** Menu popup button ***/
		$('#menuContainer button.expandButton').click(function() {
			$('#menuContainer ul.menu.tablet').fadeToggle();
		});

	} else {
		// the width of browser is less then 700px
	}

	/****************************************************
	 *
	 *  Calendar related objects, and methods
	 *
	 ****************************************************/
	var defaultEventSource = {
		url: '/api/events',
		error: function() {
			$('#script-warning').show();
		}
	}

	var dayEventSource = {
		url: '/api/events',
		error: function() {
			$('#script-warning').show();
		}
	}

	var dayVisit = false;

	$('#calendar').fullCalendar({
		defaultDate: new Date(),
		editable: true,
		header: {
			left: 'prev,next title',
			center: 'month, agendaWeek, agendaDay',
			right: ''
		},
		selectable: true,
		selectHelper: true,
		select: function(start, end, jsev, view) {
			if ( view.name == "agendaWeek" || view.name == "agendaDay") {
				window.location = "/schedule?start="+start.format()+"&end="+end.format();
			}
		},
		eventLimit: true, // allow "more" link when too many events
		events: defaultEventSource,
		loading: function(bool) {
			$('#loading').toggle(bool);
		},
		viewRender: function(view, element) {
			if ( view.name == "month" && dayVisit ) {
				dayVisit = false;
	            $('#calendar').fullCalendar('removeEventSource', dayEventSource);
				$('#calendar').fullCalendar('addEventSource', defaultEventSource);
			} else if ( ( view.name == "agendaWeek" || view.name == "agendaDay" ) && !dayVisit ) { // Week view defaults to dayview data for now.
				dayVisit = true;
	            $('#calendar').fullCalendar('removeEventSource', defaultEventSource);
				$('#calendar').fullCalendar('addEventSource', dayEventSource);
			}
		},
		dayClick: function(date, jsEvent, view) {
			if ( view.name == "agendaDay" ){
				window.location = "/schedule/" + date.format();
			}
	        $('#calendar').fullCalendar('changeView', 'agendaDay' );
	        $('#calendar').fullCalendar('gotoDate', date);
	        $('#calendar').fullCalendar('refetchEvents');
    	}
	});

	/****************************************************
	 *
	 *  TimePicker related objects, and methods
	 *
	 ****************************************************/
	$('.clockpicker').clockpicker()
	    .find('input').change(function(){
	        console.log(this.value);
	    });
	$('#demo-input').clockpicker({
	    autoclose: true
	});


});
